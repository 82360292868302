import React, { useContext } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'

import { AuthContext } from '@/contexts/auth'
import Campaign from '@/pages/Intranet/Campaign'
import Campaigns from '@/pages/Intranet/Campaigns'
import Companies from '@/pages/Intranet/Companies'
import Company from '@/pages/Intranet/Company'
import Notices from '@/pages/Intranet/Notices'
import Users from '@/pages/Intranet/Users'
import Apps from '@/pages/Intranet/Apps'
import Keys from '@/pages/Intranet/Keys'

const PrivateRoutes = () => {
    const { logged } = useContext(AuthContext)

    const location = useLocation()

    if (!logged) return <Navigate to="/auth/login" />

    if (location.pathname === '/') {
        return (
            <Navigate to="/companies" />
        )
    }

    return (
        <Routes>
            <Route exact path="/companies" element={<Companies />} />
            <Route exact path="/companies/:companyUuid" element={<Company />} />
            <Route exact path="/campaigns" element={<Campaigns />} />
            <Route exact path="/campaigns/:campaignUuid" element={<Campaign />} />
            <Route exact path="/notices" element={<Notices />} />
            <Route exact path="/users" element={<Users />} />
            <Route exact path="/apps" element={<Apps />} />
            <Route exact path="/keys" element={<Keys />} />
        </Routes>
    )
}

export default PrivateRoutes
