import { StreamingPartner } from '@/types/streamingPartner'

export const streamingPartners: { value: StreamingPartner, label: string }[] = [
    {
        value: 'Mídia de Impacto',
        label: 'Mídia de Impacto'
    },
    {
        value: 'Virtual Cast',
        label: 'Virtual Cast'
    },
    {
        value: 'Portal Rádios',
        label: 'Portal Rádios'
    },
    {
        value: 'SuperAudio',
        label: 'SuperAudio'
    },
    {
        value: 'AudioStream',
        label: 'AudioStream'
    },
    {
        value: 'Youngtech',
        label: 'Youngtech'
    },
    {
        value: 'Loopert',
        label: 'Loopert'
    }
]
