import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'

import ApiService from '@/services/Api'
import {
    Add as AddIcon,
    EditOutlined as EditIcon,
    DeleteOutline as DeleteIcon,
    ContentCopyOutlined as CopyIcon
} from '@mui/icons-material'
import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    TextField,
    Button,
    TablePagination,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    FormControlLabel,
    Switch,
    IconButton
} from '@mui/material'

import useStyles from './style'

interface IKey {
    uuid?: string;
    title: string;
    description?: string;
    expiresInterval: number;
    generateNewKeyWhenExpires: boolean;
    apiToken: string;
    permissions: string;
}

const Keys: React.FC = () => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(6)
    const [keys, setKeys] = useState<IKey[]>([])
    const [key, setKey] = useState<IKey | null>(null)
    const [loadingKey, setLoadingKey] = useState(false)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [openedKeyDialog, setOpenedKeyDialog] = useState(false)
    const [loadingDeleteKey, setLoadingDeleteKey] = useState(false)
    const [openedDeleteKeyDialog, setOpenedDeleteKeyDialog] = useState(false)

    const fetchKeys = async () => {
        try {
            const response = await ApiService.get('/key/list')
            return response.data
        } catch (error) {
            console.error('Erro ao buscar chaves: ', error)
            return []
        }
    }

    useEffect(() => {
        const loadKeys = async () => {
            const fetchedKeys = await fetchKeys()
            setKeys(fetchedKeys)
        }
        loadKeys()
    }, [])

    const handleOpenKeyDialog = (keyToEdit?: IKey) => {
        const initialKeyData = keyToEdit || {
            title: '',
            description: '',
            expiresInterval: 10,
            generateNewKeyWhenExpires: false,
            apiToken: '',
            permissions: ''
        }

        setKey(initialKeyData)
        setOpenedKeyDialog(true)
    }

    const saveKey = async (): Promise<void> => {
        if (!key) {
            enqueueSnackbar('Nenhum dado  fornecido.', { variant: 'error' })
            return
        }

        const requiredFields: { key: keyof typeof key, label: string }[] = [
            { key: 'title', label: 'título' },
            { key: 'expiresInterval', label: 'intervalo de expiração' }
        ]

        for (const { key: fieldKey, label } of requiredFields) {
            if (!key[fieldKey]) {
                enqueueSnackbar(`O ${label} é obrigatório e não pode estar vazio.`, { variant: 'error' })
                return;
            }
        }

        setLoadingKey(true)

        try {
            const endpoint = key.uuid ? `/key/${key.uuid}` : "/key"
            const method = key.uuid ? ApiService.put : ApiService.post
            const response = await method(endpoint, key)
            setKeys(prevKeys =>
                key.uuid
                    ? prevKeys.map(k => k.uuid === key.uuid ? response.data : k)
                    : [response.data, ...prevKeys]
            )
            enqueueSnackbar('Chave de API salva com sucesso!', { variant: 'success' })
            handleCloseKeyDialog()
        } catch (e) {
            console.error('Erro ao salvar a Chave:', e)
            enqueueSnackbar('Ocorreu um erro ao salvar a chave.', { variant: 'error' })
        } finally {
            setLoadingKey(false)
        }
    }

    const handleDeleteKey = async () => {
        setLoadingDeleteKey(true)
        try {
            await ApiService.delete(
                `/key/${key?.uuid}`
            )
            setKeys(prevKeys => prevKeys.filter(k => k.uuid !== key?.uuid))
            enqueueSnackbar('Chave de API removida com sucesso.', {
                variant: 'success'
            })
            setOpenedDeleteKeyDialog(false)
        } catch (error) {
            enqueueSnackbar(
                'Ocorreu um erro ao remover a chave.',
                {
                    variant: 'error'
                }
            )
        } finally {
            setLoadingDeleteKey(false)
        }
    }

    const handleCopy = async (apiToken: string) => {
        try {
            await navigator.clipboard.writeText(apiToken)
            enqueueSnackbar('Chave de API copiada com sucesso', {
                variant: 'success'
            })
        } catch (err) {
            enqueueSnackbar('Ocorreu um erro ao copiar a chave', {
                variant: 'error'
            })
        }
    }

    const handleChangePage = (_: unknown, newPage: React.SetStateAction<number>) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const openDeleteKeyDialog = async (key: IKey) => {
        setKey(key)
        setOpenedDeleteKeyDialog(true)
    }

    const handleCloseKeyDialog = () => {
        setOpenedKeyDialog(false)
        setKey(null)
    }

    const filteredKeys = keys.filter((key) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase()
        const matchesName = key.title.toLowerCase().includes(lowerCaseSearchTerm)
        return matchesName
    })

    const apiKeysToDisplay = filteredKeys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Buscar por título"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        size="small"
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenKeyDialog()}
                        size="small"
                    >
                        Criar Chave
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="keys table" >
                            <TableHead>
                                <TableRow sx={{ height: '30px' }}>
                                    <TableCell>Título</TableCell>
                                    <TableCell>Descrição</TableCell>
                                    <TableCell>Token</TableCell>
                                    <TableCell align="center">Expiração</TableCell>
                                    <TableCell align="center">Gerar nova chave?</TableCell>
                                    <TableCell align="center">Permissões</TableCell>
                                    <TableCell align="right">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {apiKeysToDisplay.map((apiKey) => (
                                    <TableRow key={apiKey.uuid} sx={{ height: '30px' }}>
                                        <TableCell component="th" scope="row">{apiKey.title}</TableCell>
                                        <TableCell className={classes.truncatedText} title={apiKey.description}>{apiKey.description}</TableCell>
                                        <TableCell className={classes.truncatedText}>{apiKey.apiToken}</TableCell>
                                        <TableCell align="center">{apiKey.expiresInterval}m</TableCell>
                                        <TableCell align="center">{apiKey.generateNewKeyWhenExpires ? 'Sim' : 'Não'}</TableCell>
                                        <TableCell align="center">{apiKey.permissions}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleCopy(apiKey.apiToken)}>
                                                <CopyIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleOpenKeyDialog(apiKey)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => openDeleteKeyDialog(apiKey)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[6, 7, 8, 9, 10]}
                            component="div"
                            count={filteredKeys.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            </Grid >

            <Dialog open={openedKeyDialog}>
                <DialogTitle>
                    {
                        !key?.uuid
                            ? 'Criando nova chave'
                            : 'Editando a chave'
                    }
                </DialogTitle>

                {key && (
                    <>
                        <Grid container spacing={2} style={{ padding: '16px' }}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    label="Título"
                                    size="small"
                                    variant="outlined"
                                    value={key.title}
                                    onChange={(e) =>
                                        setKey({ ...key, title: e.target.value })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    type="textarea"
                                    label="Descrição" size="small" color="primary" variant="outlined"
                                    value={key.description}
                                    onChange={({ target }) => setKey({ ...key, description: target.value })}
                                    multiline rows={4}
                                    inputProps={{ maxLength: 255 }}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    type="textarea"
                                    label="Token de acesso" size="small" color="primary" variant="outlined"
                                    value={key.apiToken}
                                    onChange={({ target }) => setKey({ ...key, apiToken: target.value })}
                                    multiline rows={4}
                                    inputProps={{ maxLength: 255 }}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Intervalo de expiração (minutos)"
                                    size="small"
                                    variant="outlined"
                                    type="number"
                                    value={key.expiresInterval}
                                    onChange={(e) =>
                                        setKey({ ...key, expiresInterval: +e.target.value })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Permissões"
                                    size="small"
                                    variant="outlined"
                                    value={key.permissions}
                                    onChange={(e) =>
                                        setKey({ ...key, permissions: e.target.value })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={key.generateNewKeyWhenExpires}
                                            onChange={({ target }) => setKey({ ...key, generateNewKeyWhenExpires: target.checked })}
                                            color="primary"
                                        />
                                    }
                                    label={'Gerar uma nova chave quando a atual expirar'}
                                />
                            </Grid>
                        </Grid>

                        <Divider />

                        <DialogActions>
                            <Button
                                onClick={handleCloseKeyDialog}
                                color="primary"
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={saveKey}
                                color="primary"
                                variant="contained"
                                disabled={loadingKey}
                                endIcon={loadingKey && (
                                    <CircularProgress size={20} color="inherit" />
                                )}
                            >
                                {key.uuid ? 'Salvar' : 'Criar'}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            <Dialog
                aria-labelledby="simple-dialog-title"
                open={openedDeleteKeyDialog}
            >
                <DialogTitle>
                    Tem certeza que deseja remover a chave {key?.title}
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setOpenedDeleteKeyDialog(false)}
                        className={classes.deleteKeyDialogCancelButton}
                        disabled={loadingDeleteKey}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleDeleteKey}
                        color="primary"
                        autoFocus
                        disabled={loadingDeleteKey}
                        endIcon={
                            loadingDeleteKey && (
                                <CircularProgress size={15} color="inherit" />
                            )
                        }
                    >
                        Remover
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Keys
