import React, { useEffect, useState } from "react";
import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    IconButton,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
    TablePagination
} from "@mui/material";
import { EditOutlined as EditIcon, DeleteOutline as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import useStyles from "./style";
import ApiService from "@/services/Api";
import { useSnackbar } from 'notistack';
import MultiStepDialog, { FormData } from "@/components/MultiStepDialog";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';


interface IApp {
    uuid?: string;
    backgroundColor?: string;
    mainColor?: string;
    secondaryColor?: string;
    textColor?: string;
    companyUuids: string[];
    barButtons?: {
        [companyUuid: string]: {
            icon: IconDefinition;
            url: string;
        }[];
    };
}

interface IAddress {
    uuid: string;
    country: string;
    state: string;
    city: string;
    neighborhood: string | null;
    street: string | null;
    number: string | null;
    complement: string | null;
    latitude: number | null;
    longitude: number | null;
}

export interface IRadio {
    uuid: string;
    name: string;
    address: IAddress;
}

const Apps: React.FC = () => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [radios, setRadios] = useState<IRadio[]>([]);
    const [openedAppDialog, setOpenedAppDialog] = useState(false);
    const [app, setApp] = useState<IApp | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const [apps, setApps] = useState<IApp[]>([]);
    const [openedCompaniesDialog, setOpenedCompaniesDialog] = useState(false);
    const [openedDeleteAppDialog, setOpenedDeleteAppDialog] =
        useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>({
        app: {
            backgroundColor: "#ffffff",
            mainColor: "#ffffff",
            secondaryColor: "#ffffff",
            textColor: "#ffffff",
            companyUuids: []
        },
        barButtons: {}
    });

    useEffect(() => {
        const loadRadios = async () => {
            try {
                const response = await ApiService.get("/company/list");
                setRadios(response.data);
            } catch (error) {
                console.error("Erro ao buscar rádios: ", error);
            }

        };
        loadRadios();
    }, []);

    const loadApps = async () => {
        try {
            const response = await ApiService.get("/app/list");
            setApps(response.data);
        } catch (error) {
            console.error("Erro ao buscar apps: ", error);
        }
    };

    useEffect(() => {
        loadApps();
    }, []);

    const handleOpenAppDialog = (appToEdit?: IApp) => {
        const initialData = appToEdit || {
            backgroundColor: "#ffffff",
            mainColor: "#ffffff",
            secondaryColor: "#ffffff",
            textColor: "#ffffff",
            companyUuids: [],
            barButtons: {}
        }

        const { barButtons, ...rest } = initialData
        setFormData({
            app: { ...rest },
            barButtons: barButtons ?? {}
        });

        setOpenedAppDialog(true);
    };

    const handleCloseAppDialog = () => {
        setOpenedAppDialog(false);
        setApp(null);
    };

    const openCompaniesDialog = (app: IApp) => {
        setApp(app);
        setOpenedCompaniesDialog(true);
    };

    const handleCloseCompaniesDialog = () => {
        setOpenedCompaniesDialog(false);
        setApp(null);
    };

    const openDeleteAppDialog = async (app: IApp) => {
        setApp(app);
        setOpenedDeleteAppDialog(true);
    };

    const handleDeleteApp = async () => {
        try {
            await ApiService.delete(
                `/app/${app?.uuid}`
            );
            setApp(null);
            setOpenedDeleteAppDialog(false);
            setApps(apps.filter((item) => item.uuid !== app?.uuid));
            enqueueSnackbar("Aplicativo removido com sucesso.", {
                variant: "success",
            });
        } catch (error: any) {
            enqueueSnackbar(
                error?.data || "Ocorreu um erro ao remover o aplicativo.",
                {
                    variant: "error",
                }
            );
        }
    };

    const renderCompanies = (app: IApp) => {
        if (app.companyUuids.length > 3) {
            return (
                <Button onClick={() => openCompaniesDialog(app)}>
                    Ver Rádios ({app.companyUuids.length})
                </Button>
            );
        }

        if (app.companyUuids.length > 0) {
            return app.companyUuids
                .map(uuid => radios.find(r => r.uuid === uuid)?.name)
                .filter(Boolean)
                .join(', ');
        }


        return 'Nenhuma rádio';
    };

    const filteredApps = apps.filter((app) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        const matchesCompany = lowerCaseSearchTerm ? app.companyUuids.some((uuid) => {
            const company = radios.find((company: IRadio) => company.uuid === uuid);
            return company && company.name.toLowerCase().includes(lowerCaseSearchTerm);
        }) : apps;

        return matchesCompany;
    });

    const handleChangePage = (_: unknown, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const appsToDisplay = filteredApps.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Buscar por rádio"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        size="small"
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenAppDialog()}
                        size="small"
                    >
                        Criar novo aplicativo
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="apps table" >
                            <TableHead>
                                <TableRow sx={{ height: '30px' }}>
                                    <TableCell align="left">Rádio</TableCell>
                                    <TableCell align="center">Cor de fundo</TableCell>
                                    <TableCell align="center">Cor principal</TableCell>
                                    <TableCell align="center">Cor secundária</TableCell>
                                    <TableCell align="center">Cor do Texto</TableCell>
                                    <TableCell align="right">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {appsToDisplay.map((app) => (
                                    <TableRow key={app.uuid} sx={{ height: '30px' }}>
                                        <TableCell align="left" >

                                            {renderCompanies(app)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Box
                                                sx={{
                                                    width: '24px',
                                                    height: '24px',
                                                    backgroundColor: app.backgroundColor,
                                                    borderRadius: '4px',
                                                    border: '1px solid #ccc',
                                                    display: 'inline-block',
                                                }}
                                                title={app.backgroundColor}
                                            />
                                        </TableCell>
                                        <TableCell align="center">  <Box
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                                backgroundColor: app.mainColor,
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                                display: 'inline-block',
                                            }}
                                            title={app.mainColor}
                                        /></TableCell>
                                        <TableCell align="center">  <Box
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                                backgroundColor: app.secondaryColor,
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                                display: 'inline-block',
                                            }}
                                            title={app.secondaryColor}
                                        /></TableCell>
                                        <TableCell align="center">  <Box
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                                backgroundColor: app.textColor,
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                                display: 'inline-block',
                                            }}
                                            title={app.textColor}
                                        /></TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleOpenAppDialog(app)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => openDeleteAppDialog(app)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[6, 7, 8, 9, 10]}
                            component="div"
                            count={filteredApps.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            </Grid>
            <Dialog
                open={openedCompaniesDialog}
                onClose={handleCloseCompaniesDialog}
            >
                <DialogTitle>Radios Associadas</DialogTitle>
                <DialogContent>
                    <Typography>
                        {app?.companyUuids
                            .map((uuid) => {
                                const company = radios.find(
                                    (r) => r.uuid === uuid
                                );
                                return company ? company.name : "";
                            })
                            .filter(Boolean)
                            .join(", ")}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCompaniesDialog} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

            <MultiStepDialog
                open={openedAppDialog}
                onClose={handleCloseAppDialog}
                loadApps={loadApps}
                initialFormData={formData}
                radios={radios}
            />

            <Dialog
                aria-labelledby="simple-dialog-title"
                open={openedDeleteAppDialog}
            >
                <DialogTitle>
                    Tem certeza que deseja remover este aplicativo?
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setOpenedDeleteAppDialog(false)}
                        className={classes.deleteAppDialogCancelButton}
                    >
                        Cancelar
                    </Button>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={handleDeleteApp}
                    >
                        Remover
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Apps;
