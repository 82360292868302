import React, { useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import * as regularIcons from '@fortawesome/free-regular-svg-icons';
import * as brandIcons from '@fortawesome/free-brands-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    TextField,
    Box,
    Grid,
    Pagination,
    useTheme,
} from '@mui/material';

const extractIcons = (iconSet: any) => {
    return Object.keys(iconSet)
        .filter((key) => key.startsWith('fa'))
        .map((key) => ({
            name: key.substring(2).toLowerCase(),
            icon: iconSet[key] as IconDefinition,
        }));
};

const solidIconList = extractIcons(solidIcons);
const regularIconList = extractIcons(regularIcons);
const brandIconList = extractIcons(brandIcons);

const allIcons = [...solidIconList, ...regularIconList, ...brandIconList];

interface IconSearchProps {
    onSelect: (icon: IconDefinition) => void;
}

const IconSelector: React.FC<IconSearchProps> = ({ onSelect }) => {
    const theme = useTheme();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);

    const ICONS_PER_PAGE = 48;

    const filteredIcons = useMemo(() =>
        allIcons.filter((icon) =>
            icon.name.toLowerCase().includes(searchTerm.toLowerCase())
        ),
        [searchTerm]
    );

    const pageCount = Math.ceil(filteredIcons.length / ICONS_PER_PAGE);

    const paginatedIcons = useMemo(() => {
        const startIndex = (currentPage - 1) * ICONS_PER_PAGE;
        const endIndex = startIndex + ICONS_PER_PAGE;
        return filteredIcons.slice(startIndex, endIndex);
    }, [filteredIcons, currentPage]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    return (
        <Grid sx={{ mt: theme.spacing(2), mb: theme.spacing(2) }}>
            <TextField
                fullWidth
                label="Buscar ícone"
                size='small'
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Digite para filtrar ícones"
                autoComplete='off'
            />

            <Box sx={{ mt: theme.spacing(2), mb: theme.spacing(2), border: '1px solid #ccc', borderRadius: '4px', p: theme.spacing(2) }}>
                <Grid container spacing={2}>
                    {paginatedIcons.map((icon, index) => (
                        <Grid
                            item
                            xs={1}
                            key={`${icon.name}-${index}`}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'scale(1.1)',
                                }
                            }}
                            onClick={() => onSelect(icon.icon)}
                        >
                            {icon.icon.iconName && <FontAwesomeIcon
                                icon={icon.icon}
                                size="2x"
                            />}
                        </Grid>
                    ))}
                </Grid>

                <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    sx={{
                        mt: theme.spacing(2),
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                    variant='outlined'
                />
            </Box>
        </Grid>
    );
};

export default IconSelector;