import React from 'react'

import { TablePagination } from '@mui/material'

interface PaginationProps {
    count: number;
    rowsPerPage: number;
    page: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Pagination: React.FC<PaginationProps> = ({
    count,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange
}) => {
    return (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'Todos' }]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        />
    )
}

export default Pagination
