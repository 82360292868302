import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    dialogItemInput: {
        padding: theme.spacing(1)
    },
    deleteKeyDialogCancelButton: {
        color: theme.palette.secondary.dark
    },
    truncatedText: {
        maxWidth: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        verticalAlign: 'middle'
    }
}))

export default useStyles
